import { memo, useMemo } from 'react'
import { useRouter } from 'next/router'
import { useTranslator } from '@/hooks'
import { getLocaleCountry } from '@dy/commons/utils'
import { FOOTER_URLS, FOOTER_SOCIAL_MEDIA_LINKS } from '../layoutConfig'
import { Button, Figure, Link } from '@dy/commons/components'
import { useModal } from '@dy/commons/hooks'
import { MODALS } from '@dy/commons/context'
import { RssSection } from './footerStyles'

const mediaStamp = {
  type: 'image' as const,
  alt: 'Stamp denominazione origine logo',
  default: {
    src:'/images/svg/i--stamp--denominazione-origine-protetta.svg',
    size: { width: 6, height: 8 }
  }
} 
const mediaFbIcon = {
  type: 'image' as const,
  alt: 'Facebook logo',
  default: {
    src:'/images/svg/i--rrss--facebook.svg',
    size: { width: 30, height: 30 }
  }
} 
const mediaInstagramIcon = {
  type: 'image' as const,
  alt: 'Instagram logo',
  default: {
    src:'/images/svg/i--rrss--instagram.svg',
    size: { width: 30, height: 30 }
  }
} 
export default memo(() => {
  const { t } = useTranslator()
  const { locale } = useRouter()
  const localeRegion = useMemo(() => getLocaleCountry(locale), [locale])
  const { toggle } = useModal(MODALS.REGION_LANG)

  return (
    <>
      <RssSection>
        <Figure className='stamp' media={mediaStamp}/>
        <div className='row-copyright'>
          <span>© Mammafiore {new Date().getFullYear()}</span>
          <Link href={FOOTER_URLS[localeRegion] || '/'}>{t('layout.footer.legal_warning')}</Link>
        </div>
        <ul className='list--rrss'>
          <li>
            <a href='https://www.facebook.com/HelloMamma-103977235287577' rel='noreferrer' target='_blank' title='facebook'>
              <Figure media={mediaFbIcon}/>
            </a>
          </li>
          <li>
            <a href={FOOTER_SOCIAL_MEDIA_LINKS[localeRegion]} rel='noreferrer' target='_blank' title='instagram'>
              <Figure media={mediaInstagramIcon}/>
            </a>
          </li>
        </ul>
        {locale !== 'en-EU' &&
          <Button onClick={toggle} className='btn--country-and-lang' outline={true} bgType='white'>{t('layout.footer.change_country_lang')}</Button>
        }
      </RssSection>
    </>
  )
})
