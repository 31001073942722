export const SEARCH_CATEGORY_IMAGE = {
  alt: 'Shop category result',
  type: 'svg',
  url: '/images/svg/i--search-category-result.svg'
}

export const SEARCH_LUPA_IMAGE = {
  url: '/images/svg/i--search.svg',
  type: 'svg',
  alt: 'Search icon'
}

export const SEARCH_TYPES_SWAP = {
  'products': 'product',
  'categories': 'shop',
  'brands': 'brand'
}

export const SEARCH_ORDER = ['categories', 'brands', 'products']

export const FOOTER_SOCIAL_MEDIA_LINKS = {
  'es-ES': 'https://www.instagram.com/hellomamma_es/',
  'fr-FR': 'https://www.instagram.com/hellomamma_fr/',
  'en-GB': 'https://www.instagram.com/hellomamma_uk/',
  'de-DE': 'https://www.instagram.com/hellomamma_de/'
}

export const FOOTER_URLS = {
  'es': '/page/aviso-legal',
  'en': '/page/aviso-legal',
  'it': '/page/aviso-legal',
  'fr': '/page/mentions-legales',
  'de': '/page/mentions-legales'
}

export const NAVBAR_BURGER_IMAGES = {
  'burger': () => ({
    alt: 'Icon burger',
    src: '/images/svg/i--burger.svg'
  }),
  'close': () => ({
    alt: 'Icon close',
    src: '/images/svg/i--burger-close.svg'
  })
}

export const ROUTES = {
  'ES': (staging) => `https://${staging ? 'staging.' : ''}shop.es.hellomamma.eu`,
  'FR': (staging) => `https://${staging ? 'staging.' : ''}shop.fr.hellomamma.eu`,
  'GB': (staging) => `https://${staging ? 'staging.' : ''}shop.gb.hellomamma.eu`,
}

export const LAYOUT_DOMAIN_COOKIES = {
  'FR': 'fr.hellomamma.eu',
  'ES': 'es.hellomamma.eu'
}

export const LAYOUT_REDIRECT_DOMAINS_PRO = {
  'FR': 'https://fr.hellomamma.eu',
  'ES': 'https://es.hellomamma.eu',
  'IC': 'https://canarias.hellomamma.eu'
}

export const LAYOUT_REDIRECT_DOMAINS_STAGING = {
  'FR': 'https://staging.fr.hellomamma.eu',
  'ES': 'https://staging.es.hellomamma.eu',
  'IC': 'https://staging.canarias.hellomamma.eu'
}

export const LAYOUT_LOCALE_BY_REGION = {
  'ES': ['es'],
  'FR': ['fr']
}

export const PRO = process.env.SITE_ENVIRONMENT !== 'development' && process.env.SITE_ENVIRONMENT !== 'local' && process.env.SITE_ENVIRONMENT !== 'staging'
export const STAGINGorPRO = process.env.SITE_ENVIRONMENT !== 'development' && process.env.SITE_ENVIRONMENT !== 'local'

export const generalLayoutStyles = {
  searchPrimary: ({ theme }) => theme.colors.orange
}


